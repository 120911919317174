import '../assets/css/profileedit.css';

import Formsy from 'formsy-react';
import { navigate, Link } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';


import Layout from '../components/layout';
// import LeftProfile from '../components/leftProfile';
import MyInput from '../components/MyInput';
import MyInputMask from '../components/MyInputMask';

import SEO from '../components/seo';
import { setCredentials } from './../core/auth';
import { isAuthenticated,getAuthenticatedUser } from '../core/util';
import closeChat from '../images/close-icon.svg';
import * as actionCreators from './../store/actions';

class ProfileEditPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            userToken:null,
            profile:null,
            successMsg:null
        };
    }

    async componentDidMount() {
        if (!isAuthenticated()) {
            navigate("/"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
        }
        let usrdata = getAuthenticatedUser();
        this.setState({ userToken: usrdata.token })
        await this.getFromDirect(usrdata.token);
    }

    getFromDirect = async(paramId) => {
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getProfile'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    token: paramId,
                })
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    this.setState({ profile: res.me })
                } else {
                    setCredentials({});
                    navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                }
            });
    }
    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = async(model) => {
        const { showLoader, hideLoader } = this.props;
        showLoader();
        model.token = this.state.userToken;
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/editProfile'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(model)
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status === 1){
                    this.setState({ successMsg: 'Your Profile Updated Successfully' })
                    setCredentials(res.me);
                } else if(res.status === 0){
                        this.setState({ successMsg: 'Your Profile Updated Failed' })
                } else if(res.status === -1) {
                    setCredentials({});
                    navigate("/login"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                }
                hideLoader();
            });

        
    }

    render() {
        const { canSubmit, profile,successMsg } = this.state;
        if(!profile) return '';
        return (
            <Layout layoutType="profileHeader">
                <SEO title="Servicecontract.com - Profile" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                {/* --------------banner--------- */}

                <div className={global.dealertoken?"profilecontainer grayish":"profilecontainer"}>
                    <div className="container">
                        <div className="profile-content">
                          {/*  <LeftProfile />*/}


                            <div className="right-side">
                                <Formsy ref="profileForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                                    <div className="form">
                                        <div className="updateaccount-container">
                                            <h1>Update Account Info <img onClick={() => navigate("/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))} className="userclose" src={closeChat} alt="" /></h1>
                                            <span className="edit-info">{successMsg}</span>
                                        </div>
                                        <div className="form-inline">
                                            <div className="left">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="firstName"
                                                        id="firstName"
                                                        placeholder="First Name"
                                                        className="form-control"
                                                        value={profile.firstName}
                                                        isLabelRequired={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="lastName"
                                                        id="lastName"
                                                        placeholder="Last Name"
                                                        className="form-control"
                                                        value={profile.lastName}
                                                        isLabelRequired={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-inline">
                                            <div className="left">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="email"
                                                        id="email"
                                                        placeholder="Email"
                                                        className="form-control"
                                                        value={profile.email}
                                                        isLabelRequired={true}
                                                        disabled={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="right">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="address"
                                                        id="address"
                                                        placeholder="Street Address"
                                                        className="form-control"
                                                        value={profile.address?profile.address  : ''}
                                                        isLabelRequired={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-inline">
                                            <div className="left">
                                                <div className="form-label-group error form-part">
                                                    <MyInput
                                                        name="city"
                                                        id="city"
                                                        placeholder="City"
                                                        className="form-control"
                                                        value={profile.city ? profile.city : ''}
                                                        isLabelRequired={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="right">
                                            
                                                <div className="form-label-group not-aplicable form-part">
                                                
                                                    {/* <MySelect
                                                        name="state"
                                                        title="State"
                                                        required={true}
                                                        value={profile.state ? profile.state : stateList[0].value}
                                                        options={stateList}
                                                    /> */}
                                                    <MyInputMask
                                                        name="zipcode"
                                                        id="zipcode"
                                                        mask="99999"
                                                        placeholder="zipcode"
                                                        className="form-control"
                                                        value={profile.zipcode ? profile.zipcode : ''}
                                                        isLabelRequired={true}
                                                        required
                                                    />
                                                </div>

                                                
                                            </div>
                                        </div>

                                        <div className="form-inline">
                                            <div className="left">
                                                <div className="form-label-group error form-part">
                                                    <MyInputMask
                                                        name="phone"
                                                        id="phone"
                                                        mask="999-999-9999"
                                                        placeholder="Phone"
                                                        className="form-control"
                                                        value={profile.phone ? profile.phone : ''}
                                                        isLabelRequired={true}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="button-container change-pass-container-btn">
                                        <button type="submit" disabled={!canSubmit} className={!canSubmit ? 'btn disabled change-pass-btn' : 'btn change-pass-btn'}>
                                            UPDATE
                                        </button>
                                        <Link className="btn change-pass-btn outline" to={"/userprofile"+(global.dealertoken?'/?dealertoken='+global.dealertoken:'')}>Back</Link>
                                    </div>
                                </Formsy>
                            </div>

                        </div>
                    </div>
                </div>

                <section className="copyright-container last-text">
                    <div className="container">
                        <div className="copyright">
                            <small>Copyright servicecontract.com © {new Date().getFullYear()}</small>
                        </div>
                    </div>

                </section>

            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        showSuccessPopup: (payload) => dispatch(actionCreators.showSuccessPopup(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEditPage);

